

@media (max-width: 767px) {
    .dropdown {
        /*    border: 0;*/
        top: 0 !important;

        width: 100% !important;

        box-shadow: none !important;
        position: relative !important;
        border: 0 !important;
    }
}

@media (min-width: 768px) {
    .navItem:hover {
        box-shadow: inset 0 -3px var(--bs-primary);
    }

    .navItem:hover > div {
        display: block;
        left: 0;
    }

    .dropdown:hover {
        display: block;
        left: 0;
    }

    .dropdown {
        border-left: 0;
        border-right: 0;

        top: 71px !important;

        display: none;
    }
}
