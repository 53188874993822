.businessTypeLink {
    .businessTypeIcon {
        opacity: 0.4;
    }

    &:hover {
        border-color: rgba(var(--bs-warning-rgb), .5) !important;
        color: var(--bs-warning) !important;
        
        .businessTypeIcon {
            opacity: 1;
        }
    }
}
