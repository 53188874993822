.myDeviceFrame {
    &.ipad_iphone {
        .iPad {
            margin-left: -5%;
        }

        .iPhone {
            position: absolute !important;
            right: 0;
            bottom: 0;
            transform: scale(0.5);
            transform-origin: bottom right;
            z-index: 10;
            max-width: 60%;
        }
    }

    &.iphone_ipad {
        .iPad {
            margin-left: 5%;
        }

        .iPhone {
            position: absolute !important;
            left: 0;
            bottom: 0;
            transform: scale(0.5);
            transform-origin: bottom left;
            z-index: 10;
            max-width: 60%;
        }
    }

    &.iphone_iphone {
        .iPhone:first-child {
            z-index: 2;
            margin-left: -50%;

            @media (max-width: 576px) {
                margin-left: -30%;
            }
        }

        .iPhone:last-child {
            width: 100%;
            z-index: 1;
            position: absolute !important;
            right: -20%;
            transform: scale(0.95);

            @media (max-width: 576px) {
                right: 0;
            }
        }
    }

    .iPhone {
        @media (max-width: 576px) {
            zoom: .7;
        }
    }
}